import './MothMirror.scss'
import React from 'react'
import * as THREE from 'three'
import MothData from '../data/mothmirror_detailed.json'
import StoneData from '../data/stonecircles_1.json'
import GeometryUtils from '../utils/GeometryUtils'
import { getCircleFieldMaterial } from '../shaders/CircleFieldShader'
import { getFlipBookMaterial } from '../shaders/FlipBookShader'
import { GetWebcamId } from '../utils/WebcamUtils'

export interface IMothMirrorProps {
  width: number
  height: number
}

interface ISceneDescription {
  data: any
  flipbookTexturePath: string
  scale: number
}

const scenes: ISceneDescription[] = [
  {
    data: MothData,
    flipbookTexturePath: 'textures/ButterflyGrid_Sparse.png',
    scale: 0.1,
  },
  {
    data: StoneData,
    flipbookTexturePath: 'textures/ButterflyGrid_Sparse.png',
    scale: 0.04,
  },
]

function getQueryParamFloat(paramName: string, defaultValue: number): number {
  const urlParams = new URLSearchParams(window.location.search)
  const str = urlParams.get(paramName)
  if (str) {
    var n = Number.parseFloat(str)
    if (!isNaN(n)) {
      return n
    }
  }

  return defaultValue
}

function getQueryParamString(paramName: string, defaultValue: string): string {
  const urlParams = new URLSearchParams(window.location.search)
  const str = urlParams.get(paramName)
  return str || defaultValue
}

export default class MothMirror extends React.Component<IMothMirrorProps> {
  private videoElementRef: HTMLVideoElement | null = null
  private divMountRef: HTMLDivElement | null = null

  private camera: THREE.PerspectiveCamera | null = null
  private renderer: THREE.WebGLRenderer | null = null

  private zoom: number = 1

  private boundWindowResizeHandler = this.onWindowResize.bind(this)

  componentDidMount() {
    // === THREE.JS CODE START ===
    const w = window.innerWidth
    const h = window.innerHeight

    this.zoom = getQueryParamFloat('zoom', 1)
    const sceneIndex = getQueryParamFloat('scene', 0)

    const clock = new THREE.Clock()

    var scene = new THREE.Scene()
    this.camera = new THREE.PerspectiveCamera(10, w / h, 0.1, 1000)
    //this.camera = new THREE.OrthographicCamera(-10, 10, 10, -10, 0.01, 1000);
    this.camera.position.set(0, 0, 1000)
    this.camera.lookAt(0, 0, 0)

    const flipbookTexture = new THREE.TextureLoader().load(
      scenes[sceneIndex].flipbookTexturePath,
    )

    flipbookTexture.generateMipmaps = false
    flipbookTexture.magFilter = THREE.LinearFilter
    flipbookTexture.minFilter = THREE.LinearFilter

    const webcamTexture = new THREE.VideoTexture(this.videoElementRef!)

    // Butterfly with webcam
    const scale = getQueryParamFloat('scale', 1) * scenes[sceneIndex].scale

    const { geometry, maxRadius, minRadius } = this.createCirclesGeometry(
      scenes[sceneIndex].data,
      1.01,
    )
    geometry.scale(scale, scale, scale)

    const material = getCircleFieldMaterial(
      webcamTexture,
      16 / 9,
      maxRadius,
      minRadius,
      new THREE.Color('#ffffff'),
      new THREE.Color('#ffffff'),
    )

    const mesh = new THREE.Mesh(geometry, material)
    mesh.position.set(0, 0, 1)
    mesh.lookAt(this.camera.position)
    scene.add(mesh)

    const planeScale = getQueryParamFloat('bgscale', 1) * 500
    const planeGeom = new THREE.PlaneGeometry(1, 1)
    planeGeom.scale(planeScale, planeScale, planeScale)

    const flipbookMaterial = getFlipBookMaterial({
      flipbookSegments: new THREE.Vector2(4, 4),
      gridSegments: new THREE.Vector2(96, 96),
      sourceTexture: webcamTexture,
      flipbookTexture: flipbookTexture,
      sourceColorBlend: 0.9,
      texAspectRatio: 16 / 9,
      animationSpeed: 0.1,
    })

    const plane = new THREE.Mesh(planeGeom, flipbookMaterial)
    scene.add(plane)

    this.renderer = new THREE.WebGLRenderer({ antialias: false, alpha: true })
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(window.innerWidth, window.innerHeight)
    this.renderer.setClearColor(0x1c0017, 1)

    this.divMountRef?.appendChild(this.renderer.domElement)

    window.addEventListener('resize', this.boundWindowResizeHandler)

    // console.log all available webcam names
    console.log(
      "Use query parameter 'camera' to select a webcam. E.g. ?camera=BRIO",
    )

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const deviceName = getQueryParamString('camera', 'BRIO')

      GetWebcamId(deviceName).then((webcamId) => {
        const constraints: MediaStreamConstraints = {
          video: {
            width: 1280,
            height: 720,
            deviceId: { exact: webcamId },
          },
        }

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            // apply the stream to the video element used in the texture

            this.videoElementRef!.srcObject = stream
            this.videoElementRef!.play()
          })
          .catch(function (error) {
            console.error('Unable to access the camera/webcam.', error)
          })
      })
    } else {
      console.error('MediaDevices interface not available.')
    }

    var animate = () => {
      requestAnimationFrame(animate)
      material.uniforms.time.value = clock.getElapsedTime()
      material.uniforms.resolution.value = new THREE.Vector2(
        window.innerWidth,
        window.innerHeight,
      )
      material.uniforms.zoom.value = this.zoom

      flipbookMaterial.uniforms.time.value = clock.getElapsedTime()
      this.renderer!.render(scene, this.camera!)
    }

    animate()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.boundWindowResizeHandler)
  }

  render() {
    return (
      <>
        <video
          ref={(mount) => {
            this.videoElementRef = mount
          }}
          id="video"
          style={{ display: 'none' }}
          autoPlay={true}
          playsInline={true}
        />

        <div
          ref={(mount) => {
            this.divMountRef = mount
          }}
        ></div>
      </>
    )
  }

  private createCirclesGeometry(circlesData: any, radiusScale: number) {
    const vertices: number[] = []
    const indices: number[] = []
    const uvs: number[] = []
    const colors: number[] = []
    const normals: number[] = []

    const nCircles = circlesData.circles.length

    let maxRadius = 0,
      minRadius = 9999999999

    for (let i = 0; i < nCircles; i++) {
      const circle = circlesData.circles[i]
      const adjustedRadius = circle.r * radiusScale
      GeometryUtils.AddUvCircle(
        vertices,
        indices,
        normals,
        uvs,
        colors,
        circle.x,
        -circle.y,
        adjustedRadius,
        16,
        16 / 9,
      )

      if (adjustedRadius > maxRadius) {
        maxRadius = adjustedRadius
      }

      if (adjustedRadius < minRadius) {
        minRadius = adjustedRadius
      }
    }

    const geometry = GeometryUtils.initGeometryIndexedWithColors(
      vertices,
      indices,
      normals,
      uvs,
      colors,
    )
    return { geometry, maxRadius, minRadius }
  }

  onWindowResize() {
    this.camera!.aspect = window.innerWidth / window.innerHeight
    this.camera!.updateProjectionMatrix()

    this.renderer!.setSize(window.innerWidth, window.innerHeight)
  }
}
