import './SoS.scss'
import bgImage from '../images/AlternateDotGrid.png'
import { useEffect } from 'react'
import YoutubeEmbed from './YoutubeEmbed'

const style1 = {
  backgroundImage: 'url(' + bgImage + ')',
  backgroundSize: '50px',
  backgroundPosition: 'center',
  backgroundRepeat: 'repeat',
  backgroundColor: 'black',
}

const style2 = {
  maxWidth: '800px',
  margin: 'auto',
  padding: '16px 32px',
  background: 'rgba(0,0,0,0.75)',
}

export default function ShapesOfSound() {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `Shapes of Sound - Painting with Light and Music`
  })

  return (
    <div style={style1} className="shapes-sounds">
      <div style={style2}>
        {/* <video autoplay={false} muted loop={true} playsInline={true} width="400">
        <source src="/videos/storefront-1080.mp4" type="video/mp4" />
      </video> 
      https://youtu.be/Uzr2jCEeM1s
      */}
        <h1>Shapes of Sound - Painting with Light and Music</h1>
        <p>
          <strong>Shapes of Sound</strong> is a synesthetic piano performance
          paired with real-time generative visuals.
          <br />
          <br />
          Performed live at "Fresh Hydrangea", a new media art show in Seattle
          (at Sam Stubblefield's Studio) featuring local artists that use
          technology in the practice.
          <br />
          (June 9, 2022)
          <br />
        </p>

        <YoutubeEmbed embedId="6_7CIj5QaWo" />

        <h1>About the artists</h1>
        <p>
          <strong>Sabin Timalsena</strong>: Programming, Visuals, Interactivity
          <br />
          Sabin is a Seattle-based Nepali creative technologist/generative
          artist. His work utilizes algorithms and advanced real-time computer
          graphics with an emphasis on geometry and natural systems.
        </p>

        <p>
          <strong>Miss Gatsby</strong>: Music
          <br />
          Miss Gatsby is a Seattle-based Korean musician who has perfect pitch
          and perfect rhythm in music. She utilizes her musical talents and
          unorthodox artistic senses to create her own unique style in music and
          art. Her multi-perspective art style comes from her natural creativity
          and desire to pursue her unbiased unique mixture of her musical and
          visual fantasies.
          <br />
        </p>
      </div>
    </div>
  )
}
