import * as THREE from "three";

export default class GeometryUtils {
  /**
   * Sets 'position', 'color' (circleData), 'uv', as attributes with indices.
   */
  public static initGeometryIndexedWithColors(
    vertices: number[],
    indices: number[],
    normals: number[],
    uvs: number[],
    circleData: number[]
  ): THREE.BufferGeometry {
    const geometry = new THREE.BufferGeometry();

    geometry.setIndex(indices);

    geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(vertices, 3)
    );

    geometry.setAttribute(
      "normal",
      new THREE.Float32BufferAttribute(normals, 3)
    );

    geometry.setAttribute(
      "circleData",
      new THREE.Float32BufferAttribute(circleData, 3)
    );

    geometry.setAttribute("uv", new THREE.Float32BufferAttribute(uvs, 2));

    return geometry;
  }

  /**
   * Creates a circle with UVs and the center coordinate embedded as vertex color.
   * Doesn't set normals.
   */
  public static AddUvCircle(
    vertices: number[],
    indices: number[],
    normals: number[],
    uvs: number[],
    circleData: number[],
    x: number,
    y: number,
    radius: number,
    segments: number,
    uvAspectRatio: number
  ) {
    // Get starting vertex index for this set of vertices
    // Note: the vertices array has vertexCount * 3 entries (for x,y,z)
    // There should be one UV per vertex
    const startIndex = vertices.length / 3;

    const angleStep = (Math.PI * 2) / segments;

    // Populate center vertex
    vertices.push(x, y, 0);
    uvs.push(0.5, 0.5);
    circleData.push(x, y, radius);
    normals.push(0, 0, 1);

    // Populate vertices
    for (let i = 0; i < segments; i++) {
      const angle = angleStep * i;

      const x1 = x + radius * Math.cos(angle);
      const y1 = y + radius * Math.sin(angle);

      const uvx = 0.5 + (Math.cos(angle) * 0.5) / uvAspectRatio;
      const uvy = 0.5 + Math.sin(angle) * 0.5;

      vertices.push(x1, y1, 0);
      uvs.push(uvx, uvy);
      circleData.push(x, y, radius);
      normals.push(0, 0, 1);

      // Add index for one triangle
      indices.push(
        startIndex,
        startIndex + i + 1,
        startIndex + ((i + 1) % segments) + 1
      );
    }
  }
}
