import "./PhotosynthesisExhibit.scss";
import bgImage from "../images/AlternateDotGrid.png";
import { useEffect } from "react";
import YoutubeEmbed from "./YoutubeEmbed";

const style1 = {
  backgroundImage: "url(" + bgImage + ")",
  backgroundSize: "50px",
  backgroundPosition: "center",
  backgroundRepeat: "repeat",
  backgroundColor: "black",
};

const style2 = {
  maxWidth: "800px",
  margin: "auto",
  padding: "16px 32px",
  background: "rgba(0,0,0,0.75)",
};

export default function PhotosynthesisExhibit() {
  useEffect(() => {
    document.title = `Photosynthesis - Interactive Art Exhibit by Sabin Timalsena`;
  });

  return (
    <div style={style1} className="photosynthesis-exhibit">
      <div style={style2}>
        <p>
          <strong>Photosynthesis</strong> is an interactive art exhibit that
          includes elements of algorightmic botany and generative art, made by{" "}
          <a href="https://www.sabin.art">Sabin Timalsena</a>.
          <br />
          <br />
          Exhibited from December 2021 till the end of February 2022 in downtown
          Seattle as part of the "Shine on Seattle" light art exhibition.
          <br />
          <br />
          This project combines numerical chaos with natural tendencies to
          create semi-abstract plant forms that respond to natural stimuli like
          light, gravity, and wind during the generation process. All trees in
          the exhibit were modeled with math and code (C#/Unity).
          <br />
          <br />
          The exhibit also uses a depth camera to detect the presence of a
          viewer and respond by scattering leaves based on viewer's movement.
        </p>
        <YoutubeEmbed embedId="iWv2EcKAsUI" square={true} />
      </div>
    </div>
  );
}
