import './Introduction.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import YoutubeEmbed from './YoutubeEmbed'

var carouselLSystem = [
  {
    image: 'images/trees/winter.jpg',
    title: 'Tree generated with custom 3D L-System',
  },
  {
    image: 'images/Prism.jpg',
    title: 'Particle simulation in C++/openFrameworks',
  },
  {
    image: 'images/cube1.jpg',
    title: 'Structure created with a custom 3D L-System',
  },
  {
    image: 'images/trees/tree1.jpg',
    title: 'Tree generated with custom 3D L-System',
  },
  {
    image: 'images/trees/tree2.jpg',
    title: 'Tree generated with custom 3D L-System',
  },
  {
    image: 'images/trees/tree3.jpg',
    title: 'Tree generated with custom 3D L-System',
  },
  {
    image: 'images/trees/tree4.jpg',
    title: 'Tree generated with custom 3D L-System',
  },
  {
    image: 'images/trees/tree5.jpg',
    title: 'Tree generated with custom 3D L-System',
  },
  {
    image: 'images/dreamlight.jpg',
    title: 'Structure created with a custom 3D L-System',
  },
  {
    image: 'images/galaxy_euler.jpg',
    title: 'Particle simulation in C++/openFrameworks',
  },
  {
    image: 'images/menger.jpg',
    title: 'Structure created with a custom 3D L-System',
  },
  {
    image: 'images/peaflower.jpg',
    title: 'Particle simulation in C++/openFrameworks',
  },
  {
    image: 'images/terra1.jpg',
    title: 'Structure created with a custom 3D L-System',
  }
]

const highlights = [
  {
    link: "/morphing",
    title: "Research"
  },
  {
    link: "/sos",
    title: "Interactive Exhibit: Shapes of Sound"
  },
  {
    link: "/photosynthesis-exhibit",
    title: "Interactive Exhibit: Photosynthesis"
  },
  {
    link: "https://www.youtube.com/watch?v=PMJHNWWDlMo",
    title: "Lucent Manifolds - Projection Mapping + 6 dof tracking"
  },
  {
    link: "https://www.youtube.com/watch?v=rj-KDmHJfS8",
    title: "Tutorial - Procedural 3D Basics"
  }
]

function Introduction() {
  return (
    <>
      <div className="highlights">
      { 
        highlights.map(x =>
            <div key={x.title} className="highlight-item" onClick={ () => { window.open(x.link, '_blank') }}>
              <div>
              {x.title}</div>
              </div>
        )
      }
      </div>

      <p>
        I think this compilation of some of my recent experiments from 2021
        might be a good introduction:
      </p>

      <YoutubeEmbed embedId="4DEvr43s2xs" />

      <div className="text-container">
        <p>
          I am a creative technologist with a passion for geometry, generative
          art, complexity and natural systems. I believe programming unlocks
          brand new forms of artistic expression, and it is also a way of
          pushing the boundaries of what's possible. I have been passionately
          programming since ~2004.{' '}
          <strong>
            Looking for part-time/freelance work in creative
            technology/experiential design.
          </strong>
        </p>
        <p>
          I am also an experienced software engineer with 8+ years experience,
          and I have worked across the stack in large distributed systems at a
          Microsoft. I have good knowledge of{' '}
          <strong>
            3D math, shaders, computer graphics, Unity, C#, SQL,
            typescript/react, etc.
          </strong>{' '}
          I also have some experience in{' '}
          <a href="https://www.sabin.art/morphing">
            computational geometry research
          </a>
          .
        </p>
        <p>
          Some of the elements that I use in my artwork:{' '}
          <a href="https://www.youtube.com/watch?v=PMJHNWWDlMo">
            projection mapping
          </a>
          ,{' '}
          <a href="https://www.instagram.com/p/CR-Adrtp5nD/">
            custom stochastic L-Systems
          </a>
          ,{' '}
          <a href="https://www.instagram.com/p/CXs6yLQrUlK/">
            algorithmic botany
          </a>
          ,{' '}
          <a href="https://www.instagram.com/p/CUmxuqVFoFe/">
            world-building experiments
          </a>
          ,{' '}
          <a href="https://www.instagram.com/p/CXYqxvDFyB8/">
            parametric curve/surface experiments
          </a>
          ,{' '}
          <a href="https://www.instagram.com/p/CK54E_dnR9t/">
            signed distance fields
          </a>
          ,{' '}
          <a href="https://www.instagram.com/p/CKBb4meHFRg/">
            real-time music visualization
          </a>
          ,{' '}
          <a href="https://www.instagram.com/p/CW7W7Z0lw0h/">
            interactivity with depth/3D sensors (Kinect/Intel Realsense/HTC
            Vive)
          </a>
          ,{' '}
          <a href="https://www.instagram.com/p/CSq--9NHZqs/">
            cellular automata
          </a>
          , <a href="https://www.instagram.com/p/CMeTwSLnLAe/">kinematics</a>,{' '}
          <a href="https://www.instagram.com/p/CK0oK9Sn-ZE/">
            particle systems
          </a>
          ,{' '}
          <a href="https://www.instagram.com/p/CT_rQXmlfPQ/">
            agent simulations
          </a>
          , etc.
        </p>
        <p>
          My work has been exhibited in various venues, as part of the {' '}
          <a href="https://www.instagram.com/p/CW7W7Z0lw0h/">
            Shine on Seattle
          </a>{' '}
          light art exhibition (2021), as{' '}
          <a href="/sos">
            interactive applications
          </a>{' '}
          in various local art events in downtown Seattle, and as part of the{' '}
          <a href="https://www.instagram.com/p/CQ8pX97DsUk/">Neo Shibuya TV</a>{' '}
          exhibition in Tokyo.
        </p>
        <p>
          Currently, the best way to find my latest work is via{' '}
          <a href="https://www.instagram.com/lumic.exe/">Instagram</a> and{' '}
          <a href="https://twitter.com/artbysabin">Twitter</a>.
          <br /> For inquiries: please email:{' '}
          <code>sabintim at outlook dot com</code>.
        </p>
      </div>

      <Carousel
        dynamicHeight={false}
        infiniteLoop={true}
        interval={3}
        showStatus={false}
        showArrows={true}
      >
        {carouselLSystem.map((x) => (
          <div>
            <img src={x.image} alt={x.title} />
            <p className="legend">{x.title}</p>
          </div>
        ))}
      </Carousel>
    </>
  )
}

export default Introduction
