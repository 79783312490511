import './Teaching.scss'
import bgImage from '../../images/AlternateDotGrid.png'
import { useEffect } from 'react'

const style1 = {
  backgroundImage: 'url(' + bgImage + ')',
  backgroundSize: '50px',
  backgroundPosition: 'center',
  backgroundRepeat: 'repeat',
  backgroundColor: 'black',
}

const style2 = {
  maxWidth: '800px',
  margin: 'auto',
  padding: '16px 32px',
  background: 'rgba(0,0,0,0.75)',
}

export default function ClassNotes() {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `Creative Coding - 2022 Class 1`
  })

  return (
    <div style={style1} className="teaching">
      <div style={style2}>
        <h1>1. <a href="https://code.visualstudio.com/download">Install VS Code</a></h1>
        <h1>2. Install "Live Server" VS code extension</h1>
        <h1>3. Download the starter p5js project</h1>
        <h1><a href="https://github.com/SabinT/p5js-starter">https://github.com/SabinT/p5js-starter</a></h1>
        <h1>4. Let's get coding!</h1>
      </div>
    </div>
  )
}
