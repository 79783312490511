import "./SocialLinks.scss";

function SocialLinks() {
  return (
    <div className="social-links-container">
      <ul>
        <li className="social-icons">
          <a
            href="https://www.instagram.com/artbysabin/"
            title="Instagram"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </li>{" "}
        <li className="social-icons">
          <a
            href="https://twitter.com/artbysabin"
            title="Twitter"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-twitter"></i>
          </a>
        </li>
        <li className="social-icons">
          <a
            href="https://www.youtube.com/channel/UCb2FXSdPHoCA5kAOVQVma4w"
            title="YouTube"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-youtube"></i>
          </a>
        </li>
        <li className="social-icons">
          <a
            href="https://soundcloud.com/sabin-timalsena"
            title="SoundCloud"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-soundcloud"></i>
          </a>
        </li>
        <li className="social-icons">
          <a
            href="https://github.com/SabinT"
            title="GitHub"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-github"></i>
          </a>
        </li>
        <li className="social-icons">
          <a
            href="https://www.linkedin.com/in/sabin-timalsena-67741262/"
            title="LinkedIn"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-linkedin-in"></i>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default SocialLinks;
