export async function GetWebcamId(nameMatch: string): Promise<string> {
  let backupDeviceId: string = "";

  var devices = await navigator.mediaDevices.enumerateDevices();

  for (const device of devices) {
    if (device.kind === "videoinput") {
      if (device.label.toLowerCase().indexOf(nameMatch.toLowerCase()) !== -1) {
        return device.deviceId;
      }

      backupDeviceId = device.deviceId;
    }
  }

  return backupDeviceId;
}
