import './Teaching.scss'
import bgImage from '../../images/AlternateDotGrid.png'
import { useEffect } from 'react'

const style1 = {
  backgroundImage: 'url(' + bgImage + ')',
  backgroundSize: '50px',
  backgroundPosition: 'center',
  backgroundRepeat: 'repeat',
  backgroundColor: 'black',
}

const style2 = {
  maxWidth: '800px',
  margin: 'auto',
  padding: '16px 32px',
  background: 'rgba(0,0,0,0.75)',
}

export default function ClassNotes() {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `Creative Coding - 2022 Class 1`
  })

  return (
    <div style={style1} className="teaching">
      <div style={style2}>
        <h1>P5JS account</h1>
        <ul>
            <li>Go to <strong>editor.p5js.org</strong></li>
            <li>Login with your google account</li>
            <li>Make sure to save sketches regularly</li>
        </ul>

        <h1>Flags ranked by difficulty</h1>
        <br/>
        <a href="https://lascarides.github.io/fussyflags/">https://lascarides.github.io/fussyflags/</a>
      </div>
    </div>
  )
}
