import './Morphing.scss'
import bgImage from '../images/AlternateDotGrid.png'
import { useEffect } from 'react'
import YoutubeEmbed from './YoutubeEmbed'

const style1 = {
  backgroundImage: 'url(' + bgImage + ')',
  backgroundSize: '50px',
  backgroundPosition: 'center',
  backgroundRepeat: 'repeat',
  backgroundColor: 'black',
}

const style2 = {
  maxWidth: '800px',
  margin: 'auto',
  padding: '16px 32px',
  background: 'rgba(0,0,0,0.75)',
}

export default function ShapesOfSound() {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `Mesh Morphing Algorithm - 3D Geometry Processing Research`
  })

  return (
    <div style={style1} className="morphing">
      <div style={style2}>
        {/* <video autoPlay={true} muted loop={true} playsInline={true} width="400">
        <source src="/videos/storefront-1080.mp4" type="video/mp4" />
      </video> 
      https://youtu.be/Uzr2jCEeM1s
      */}
        <h1>Mesh Morphing Algorithm - 3D Geometry Processing Research</h1>

        <p>
          In 2014-2015, me and <a href="https://people.rit.edu/cxpigm/">Dr. Chao Peng</a> developed a novel 3D metamorphosis algorithm.
          The algorithm automatically establishes correspondence to facilitate a morphing animation between two arbitrary genus-0 3D
          models according to a few salient features picked by the user (e.g., eyes, nose, etc). <br/>
          Invited for presentation at SMI17 (International Convention on Shape, Solid, Structure, &amp; Physical Modeling), Berkeley, California, 2017.
        </p>

        <p>
          Here is a <a href="https://www.sciencedirect.com/science/article/abs/pii/S0097849316300681?via%3Dihub">link to the full paper</a>.
          Demo in the video/images below:
        </p>

        <YoutubeEmbed embedId="gGYCSXfbhow" />

        <p>
          The algorithm is in fact a pipeline of several algorithms, that include things like progressive mesh simplification, spherical parameterization, feature alignment, re-meshing and re-texturing.
          A follow up on the original paper was the addition of texturing support for morphing meshes 
          (<a href="http://www.iadisportal.org/digital-library/texture-remapping-and-morphing-integrated-with-spherical-mesh-parameterization">link to paper</a>).
        </p>

        <p>
          Here's an image that shows the result of morphing on various 3D models (including 3-way morphing).
          <div className="image">
            <img src="images/morphingsummary.jpg" alt="Results of mesh morphing"></img>
          </div>
        </p>
      </div>
    </div>
  )
}
