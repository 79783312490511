import PropTypes from "prop-types";
import "./YoutubeEmbed.scss";

const YoutubeEmbed = ({ embedId, square }: { embedId: string, square?: boolean }): JSX.Element => {
  return (
    <div className="videoWrapperOuter">
      <div className="videoWrapperInner">
        <iframe
          width={square ? "450": "800"}
          height="450"
          src={`https://www.youtube.com/embed/${embedId}?autoplay=1&loop=1&playlist=${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  );
};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
