import './ShapesOfSound.scss'
import bgImage from '../images/AlternateDotGrid.png'
import { useEffect } from 'react'
import YoutubeEmbed from './YoutubeEmbed'

const style1 = {
  backgroundImage: 'url(' + bgImage + ')',
  backgroundSize: '50px',
  backgroundPosition: 'center',
  backgroundRepeat: 'repeat',
  backgroundColor: 'black',
}

const style2 = {
  maxWidth: '800px',
  margin: 'auto',
  padding: '16px 32px',
  background: 'rgba(0,0,0,0.75)',
}

export default function ShapesOfSound() {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `Shapes of Sound - Painting with Light and Music`
  })

  return (
    <div style={style1} className="shapes-sounds">
      <div style={style2}>
        {/* <video autoplay={false} muted loop={true} playsInline={true} width="400">
        <source src="/videos/storefront-1080.mp4" type="video/mp4" />
      </video> 
      https://youtu.be/Uzr2jCEeM1s
      */}
        <h1>Shapes of Sound - Painting with Light and Music</h1>
        <p>
          <strong>Shapes of Sound</strong> is a realtime generative light art
          project, where a digital painting is built in an emergent fashion
          according to the notes of a musical accompaniment. The musical
          accompaniment is a pre-programmed automatic sonata, which can be
          augmented (optional) with interaction. As the digital painting is
          built in real-time, the audience will have the option to influence the
          painting (and the music), by playing keys on a digital keyboard. As
          harmony/discord is added to the music, the painting reacts
          accordingly.
        </p>

        <p>
          <strong>The Shapes:</strong>
          <br />
          <br />
          The surface for visualization is a large blank canvas. The surface is
          lit with an accurate and distortion-free projection. The projection
          will contain elements that separately visualize various elements of
          the music (notes, instruments, parameter changes). The blank canvas
          also represents a deviation from the traditional ways of painting,
          since the paint here is not acrylic or oil, but math and code.
          <br />
          The visualization will take into account every note from every
          instrument in the musical tracks, driving a variety of colorful
          paintings with the intention of capturing the bright colors of the
          summer. An instrument (keyboard; optional) will also be available for
          the audience; with which they can play with to influence the real-time
          digital painting.
        </p>

        <p>
          <strong>The Sounds:</strong>
          <br />
          <br />
          The music for this exhibit will be arranged in collaboration with
          Seattle pianist Miss Gatsby. The arrangement will be done in FL
          Studio, which will have a live link with the visualization program
          written in Unity. This link will enable visualization of both
          pre-programmed music, as well as live interaction, with the ability to
          seamlessly combine the two.
        </p>

        <h1>Construction</h1>
        <YoutubeEmbed embedId="Uzr2jCEeM1s" />
        <p>
          Equipment (please refer to the diagram above):
          <ol>
            <li>An easel (that fits a 6' canvas)</li>
            <li>Canvas (gessoed/painted matte wood panel)</li>
            <li>Projector</li>
            <li>(Portable) Monitor (monitoring/control of AV)</li>
            <li>Gaming PC</li>
          </ol>
        </p>
        <p>
          Software setup:
          <ol>
            <li>
              A digital audio workstation (FL Studio) to manage
              playback/performance
            </li>
            <li>
              Real-time music visualization/projection mapping software (made in
              Unity)
            </li>
          </ol>
        </p>

        <h1>About the artists</h1>
        <p>
          <strong>Sabin Timalsena</strong>: Programming, Visuals, Interactivity
          <br />
          Sabin is a Seattle-based Nepali creative technologist/generative
          artist. His work utilizes algorithms and advanced real-time computer
          graphics with an emphasis on geometry and natural systems.
        </p>

        <p>
          <strong>Miss Gatsby</strong>: Music
          <br />
          Miss Gatsby is a Seattle-based Korean musician who has perfect pitch
          and perfect rhythm in music. She utilizes her musical talents and
          unorthodox artistic senses to create her own unique style in music and
          art. Her multi-perspective art style comes from her natural creativity
          and desire to pursue her unbiased unique mixture of her musical and
          visual fantasies.
          <br />
        </p>

        <h1>Contact Information</h1>
        <p>
          You can reach Sabin Timalsena via email at{' '}
          <code>sabintim at outlook dot com</code>
          <br />
          You can also find links to his social accounts at{' '}
          <a href="https://www.sabin.art">www.sabin.art</a>.
        </p>
      </div>
    </div>
  )
}
