import './App.scss'
import './Glitch.scss'
import Introduction from './components/Introduction'
import bgImage from './images/AlternateDotGrid.png'
import qrCode from './images/qr-code-linktree.png'
import SocialLinks from './components/SocialLinks'
import MothMirror from './components/MothMirror'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ShapesOfSound from './components/ShapesOfSound'
import PhotosynthesisExhibit from './components/PhotosynthesisExhibit'
import SoS from './components/SoS'
import CreativeCodingClass1 from './components/Teaching/CreativeCodingClass1'
import CreativeCodingClass2 from './components/Teaching/CreativeCodingClass2'
import Morphing from './components/Morphing'

const style = {
  backgroundImage: 'url(' + bgImage + ')',
  backgroundSize: '50px',
  backgroundPosition: 'center',
  backgroundRepeat: 'repeat',
  backgroundColor: 'black',
}

function HomePage() {
  return (
    <div style={style} className="App">
      <div>
        <header className="App-header">
          <h1 className="glitch" data-text="Sabin Timalsena">
            Sabin Timalsena
          </h1>
          <h2>Generative Art | Computer Graphics | Interactive | XR</h2>
        </header>
        {/* </div>
      <div className="center"> */}
        <SocialLinks />
        {/* <div className="homePage-videos">
          <video
            autoPlay={true}
            muted
            loop={true}
            playsInline={true}
            width="250"
          >
            <source src="/videos/blood-tree.mp4" type="video/mp4" />
          </video>
          <video
            autoPlay={true}
            muted
            loop={true}
            playsInline={true}
            width="250"
          >
            <source src="/videos/cosmopolis.mp4" type="video/mp4" />
          </video>
        </div> */}
        <Introduction />
      </div>
    </div>
  )
}

function App() {
  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/mothmirror">
          <MothMirror width={400} height={400} />
          <div className="Qr-Code">
            <img src={qrCode} alt="QR code for www.instagram.com/lumic.exe" />
          </div>
        </Route>
        <Route path="/shapesofsound">{<ShapesOfSound />}</Route>
        <Route path="/sos">{<SoS />}</Route>
        <Route path="/morphing">{<Morphing />}</Route>
        <Route path="/photosynthesis-exhibit">
          {<PhotosynthesisExhibit />}
        </Route>
        <Route path="/exhibit">{<PhotosynthesisExhibit />}</Route>
        <Route path="/class1">{<CreativeCodingClass1 />}</Route>
        <Route path="/class2">{<CreativeCodingClass2 />}</Route>

        <Route path="/about">
          <HomePage />
        </Route>

        <Route
          path="/qrcode"
          component={() => {
            window.location.href = 'https://www.sabin.art'
            return null
          }}
        />

        <Route
          path="/"
          component={() => {
            window.location.href = 'https://www.sabin.art'
            return null
          }}
        />
      </Switch>
    </Router>
  )
}

export default App
