import * as THREE from "three";

/**
 * Expected attributes: position, normal, color, uv
 */
const vertexShader = `
#ifdef GL_ES
precision highp float;
#endif

varying vec2 texcoord;

void main() {
  texcoord = uv;

  vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
  gl_Position = projectionMatrix * modelViewPosition;
}
`;

const fragmentShader = `
#ifdef GL_ES
precision highp float;
#endif

varying vec2 texcoord;

uniform float texAspectRatio;
uniform sampler2D sourceTexture;
uniform sampler2D flipbookTexture;
uniform vec2 gridSegments;
uniform vec2 flipbookSegments;
uniform float sourceColorBlend;
uniform float time;
uniform float animationSpeed;

// BEGIN: MATH Utils

mat2 rotate2d(in float radians){
    float c = cos(radians);
    float s = sin(radians);
    return mat2(c, -s, s, c);
}

float map(in float value, in float inMin, in float inMax, in float outMin, in float outMax) {
    return outMin + (outMax - outMin) * (value - inMin) / (inMax - inMin);
}

vec2 rotate(
    in vec2 st,
    in float radians,
    in vec2 center)
{
    return rotate2d(radians) * (st - center) + center;
}

// END MATH STUff

// BEGIN FLIPBOOK STUFF

ivec2 GetFlipbookCell(int index, ivec2 flipbookResolution) {
    // Note: the text coordinates in DirectX are (0,0) at bottom right
    // But the flipbooks created in photoshop are generally created with (0,0) at top left
    // TODO check for WebGL
    return ivec2(
        index % flipbookResolution.x,
        flipbookResolution.y - index / flipbookResolution.x
    );
}

/**
 * Gets a coordinate in range [0, 1] within a cell after dividing the current UV
 * space into grid cells.
 */
vec2 GetGridCellUv(vec2 uv, ivec2 gridResolution) {
    vec2 cellWidth = vec2(1.0f, 1.0f) / vec2(gridResolution);
    return mod(uv, cellWidth) / cellWidth;
}

/**
 * Imagine the UV space is divided into cells in a grid of size segmentsX by segmentsY.
 * This functions returns the index of the cell in the grid where 'uv' lies.
 */
ivec2 GetGridCell(vec2 uv, ivec2 gridResolution) {
    return ivec2(floor(uv * vec2(gridResolution)));
}

vec2 GetGridCellCenterUv(vec2 uv, ivec2 gridResolution) {
    ivec2 gridIndex = GetGridCell(uv, gridResolution);

    vec2 cellWidth = vec2(1.0f, 1.0f) / vec2(gridResolution);

    return cellWidth / 2.0f + vec2(gridIndex) * cellWidth;
}

vec2 GetFlipBookCellUv(vec2 uv, int flipbookIndex, ivec2 gridResolution, ivec2 flipbookResolution) {
    vec2 gridUv = GetGridCellUv(uv, gridResolution);

    ivec2 flipBookCell = GetFlipbookCell(flipbookIndex, flipbookResolution);

    vec2 flipBookCellSize = vec2(1.0, 1.0) /  vec2(flipbookResolution);
    return vec2(flipBookCell) * flipBookCellSize 
    + vec2(gridUv.x, gridUv.y) * flipBookCellSize
    - vec2(0, flipBookCellSize.y);
}

// END FLIPBOOK STUFF

vec2 animateUv(vec2 uv) {
    return uv;
}

void main()
{
    // Find the UV to lookup source texture
    vec2 sourceUv = GetGridCellCenterUv(texcoord, ivec2(gridSegments));

    // TODO: tiling and offset on the sourceUV
    // TODO: add param to flip the texture
    vec4 sourceColor = texture2D(sourceTexture, (sourceUv - 0.5) * vec2(-1.0, texAspectRatio) + 0.5 );

    // Pick a flipbook cell based on the brightness
    int totalFlipbookImages = int(flipbookSegments.x * flipbookSegments.y);

    //float brightness = sourceColor.r * 0.3 + sourceColor.g * 0.59 + sourceColor.b * 0.11;
    float brightness = (sourceColor.r + sourceColor.g + sourceColor.b) / 3.0f;
    //brightness = clamp(brightness, 0, 1);

    vec4 col;

    vec3 fancyCol = 0.5 + 0.5 * cos(time * 0.1 + (texcoord.xyx * 4.0) + vec3(6, 2, 4));

    // TODO add thresholding
    if (brightness >= 0.0f && brightness <= 1.0f) {
        int flipbookIndex = int(
            float(totalFlipbookImages - 1) * brightness
        );

        vec2 fUv = GetFlipBookCellUv(texcoord, flipbookIndex, ivec2(gridSegments), ivec2(flipbookSegments));
        col = texture2D(flipbookTexture, fUv);
        col = mix(col, col * sourceColor, sourceColorBlend);
    } else {
        col = vec4(0.);
    }

    // Fancy moving colors
    col += vec4(fancyCol, 1.0) * 0.1;
    col *= vec4(fancyCol, 1.0) * 0.9;

    gl_FragColor = col;
}
`;

export function getFlipBookMaterial(options: {
  sourceTexture: THREE.Texture;
  flipbookTexture: THREE.Texture;
  gridSegments: THREE.Vector2;
  flipbookSegments: THREE.Vector2;
  sourceColorBlend: number;
  texAspectRatio: number;
  animationSpeed: number;
}): THREE.ShaderMaterial {
  return new THREE.ShaderMaterial({
    uniforms: {
      texAspectRatio: { value: options.texAspectRatio },
      sourceTexture: { value: options.sourceTexture },
      flipbookTexture: { value: options.flipbookTexture },
      gridSegments: { value: options.gridSegments },
      flipbookSegments: { value: options.flipbookSegments },
      sourceColorBlend: { value: options.sourceColorBlend },
      animationSpeed: { value: options.animationSpeed },
      time: { value: 1.0 },
      deltaTime: { value: 1.0 },
    },

    vertexShader: vertexShader,

    fragmentShader: fragmentShader,
    transparent: true,
  });
}
